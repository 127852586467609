import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import MainHomepage from './Pages/MainHomepage';


function App() {
  return (
    <Router>
    <Routes>
    <Route path="/" element={<MainHomepage />} />
  </Routes>
</Router>
  );
}

export default App;
