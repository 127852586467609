import React from 'react'
import Homepage from '../components/Homepage'
import Hero2 from '../components/Hero2'
import LaliInfo from '../components/LaliInfo'
import Footer from '../components/Footer'

const MainHomepage = () => {
  return (
    <div>
    <Homepage/>
    
    </div>
  )
}

export default MainHomepage