import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Mail, Instagram } from 'lucide-react';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuVariants = {
    closed: {
      opacity: 0,
      x: "100%",
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    },
    open: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  };

  const linkVariants = {
    closed: { opacity: 0, y: 20 },
    open: { opacity: 1, y: 0 }
  };

  return (
    <header className='p-4 overflow-hidden md:pt-10 px-6 md:px-10 absolute w-full max-w-[1500px] mx-auto z-50'>
      <nav className='flex max-md:mt-2 items-center justify-between'>
        <a href='/'>
        <svg className='md:h-10 h-10 '  xmlns="http://www.w3.org/2000/svg" width="56.273" height="56.277" viewBox="0 0 56.273 56.277">
  <g id="Artwork_3" data-name="Artwork 3" transform="translate(28.137 28.139)">
    <g id="Group_16" data-name="Group 16" transform="translate(-28.137 -28.139)">
      <path id="Path_53" data-name="Path 53" d="M45.3,43.792a21.14,21.14,0,0,0-1.854-29.8,21.132,21.132,0,0,0-29.831,1.158,21.139,21.139,0,0,0,1.849,29.8A21.144,21.144,0,0,0,45.3,43.792Z" transform="translate(-1.321 -1.331)" fill="#451443"/>
      <path id="Path_54" data-name="Path 54" d="M39.258,57.7c-8.416,1.6-18.574-1.376-26.65-8.682S.544,31.918,1.293,23.385A28.138,28.138,0,0,0,39.258,57.7Z" transform="translate(0 -3.709)" fill="#451443"/>
      <path id="Path_55" data-name="Path 55" d="M50.223,7.251a28.187,28.187,0,0,0-30-4.97c8.416-1.6,18.568,1.381,26.645,8.682S58.937,28.072,58.188,36.6A28.19,28.19,0,0,0,50.223,7.251Z" transform="translate(-3.208 0)" fill="#451443"/>
      <path id="Path_56" data-name="Path 56" d="M53.92,51.53c4.609-5.1,5.448-12.573,2.984-19.928A22.772,22.772,0,0,1,34.39,56.5C41.957,58.215,49.311,56.628,53.92,51.53Z" transform="translate(-5.454 -5.012)" fill="#451443"/>
      <path id="Path_57" data-name="Path 57" d="M11.249,13.063a22.87,22.87,0,0,1,16.736-7.5C20.424,3.85,13.07,5.438,8.456,10.54S3.008,23.114,5.472,30.469A22.875,22.875,0,0,1,11.249,13.063Z" transform="translate(-0.653 -0.777)" fill="#451443"/>
    </g>
  </g>
</svg>        </a>
       


        {/* Desktop Menu */}
        <div className='hidden md:flex items-center '>
          <NavLinks />
        </div>

        {/* Mobile Burger Menu */}
        <button
          className={`md:hidden z-50 burger-menu ${isOpen ? 'open' : ''}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        {/* Mobile Menu Overlay */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={menuVariants}
              className="fixed inset-0 bg-[#f1e4cd65] backdrop-blur-[10px] flex flex-col items-center justify-center md:hidden"
            >
              <motion.div
                variants={{
                  open: { transition: { staggerChildren: 0.1, delayChildren: 0.2 } },
                  closed: { transition: { staggerChildren: 0.05, staggerDirection: -1 } }
                }}
                className="flex flex-col items-center gap-8"
              >
                <NavLinks variants={linkVariants} />
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </header>
  );
};

const NavLinks = ({ variants }) => (
  <>
    <motion.a
      href="mailto:lalicares@gmail.com"
      className="flex items-center  text-[#5c1c54] hover:scale-105 transition-all duration-300 hover:text-gray-600 "
      variants={variants}
    >
<svg className='h-10' xmlns="http://www.w3.org/2000/svg" width="56.274" height="56.265" viewBox="0 0 56.274 56.265">
  <g id="Artwork_6" data-name="Artwork 6" transform="translate(28.137 28.133)">
    <g id="Group_21" data-name="Group 21" transform="translate(-28.137 -28.133)">
      <path id="Path_70" data-name="Path 70" d="M26.38,0H29.9c.958.125,1.922.218,2.873.38A27.606,27.606,0,0,1,46.734,7.055a27.674,27.674,0,0,1,9.108,16.233c.182,1.023.29,2.059.432,3.089v3.517c-.082.67-.153,1.341-.248,2.008A27.206,27.206,0,0,1,48.3,47.694c-7.222,7.228-15.945,9.919-25.969,7.941A26.837,26.837,0,0,1,5.744,45.11,27.355,27.355,0,0,1,.405,23.468,27.205,27.205,0,0,1,8.064,8.462,27.572,27.572,0,0,1,23.291.433C24.314.252,25.35.142,26.38,0Zm1.728,40.921q7.169,0,14.338,0a3.543,3.543,0,0,0,3.824-3.841q0-8.927,0-17.854a3.545,3.545,0,0,0-3.846-3.874q-14.283,0-28.566,0a3.548,3.548,0,0,0-3.852,3.868c0,5.915.046,11.83-.025,17.744a3.777,3.777,0,0,0,4.008,3.982C18.695,40.861,23.4,40.921,28.108,40.921Z" transform="translate(0)" fill="#3c0f34"/>
      <path id="Path_71" data-name="Path 71" d="M118.432,134.423v.7q0,8.241,0,16.482c0,1-.114,1.108-1.1,1.108H89.09c-.98,0-1.094-.115-1.094-1.116q0-8.241,0-16.482v-.677c.242.181.395.289.541.405q6.753,5.362,13.505,10.725a1.553,1.553,0,0,0,2.346,0q6.647-5.275,13.292-10.552C117.89,134.845,118.1,134.681,118.432,134.423Z" transform="translate(-75.075 -114.686)" fill="#3c0f34"/>
      <path id="Path_72" data-name="Path 72" d="M107.817,124.481h24.511l.143.133a2.467,2.467,0,0,0-.409.216q-5.769,4.571-11.527,9.156c-.287.229-.469.28-.787.026q-5.792-4.63-11.608-9.231C108.048,124.708,107.966,124.62,107.817,124.481Z" transform="translate(-91.987 -106.204)" fill="#3c0f34"/>
    </g>
  </g>
</svg>
    </motion.a>
    <motion.a
      href="https://www.instagram.com/lali_india"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center hover:scale-105 transition-all duration-300 text-[#5c1c54] hover:text-gray-600 "
      variants={variants}
    >
      <svg className='h-10' xmlns="http://www.w3.org/2000/svg" width="56.273" height="56.273" viewBox="0 0 56.273 56.273">
  <g id="Artwork_5" data-name="Artwork 5" transform="translate(28.136 28.137)">
    <g id="Group_17" data-name="Group 17" transform="translate(-28.137 -28.137)">
      <path id="Path_58" data-name="Path 58" d="M0,28.1A28.137,28.137,0,1,1,28.087,56.273,28.151,28.151,0,0,1,0,28.1Zm10.806.006h-.049c0,2.138-.014,4.276.008,6.413a16.945,16.945,0,0,0,.165,2.408A9.027,9.027,0,0,0,16.214,44.3a12.152,12.152,0,0,0,5.029,1.031q6.582.031,13.163,0a18.119,18.119,0,0,0,2.853-.214,8.961,8.961,0,0,0,7.49-5.852,12.334,12.334,0,0,0,.765-4.407c0-4.35.01-8.7-.006-13.051a16.449,16.449,0,0,0-.167-2.464c-.557-3.573-2.334-6.239-5.8-7.594a12.177,12.177,0,0,0-4.4-.811c-4.669-.023-9.338-.03-14.007,0a11.561,11.561,0,0,0-5.471,1.3c-3.2,1.721-4.616,4.586-4.826,8.051C10.679,22.887,10.806,25.5,10.806,28.106Z" transform="translate(0 0)" fill="#3c0f34"/>
      <path id="Path_59" data-name="Path 59" d="M106.934,94.028c2.307,0,4.614-.021,6.92.012a10.876,10.876,0,0,1,2.228.261A6.132,6.132,0,0,1,121,99.821a16.781,16.781,0,0,1,.113,1.906c.01,4.277.037,8.554-.018,12.83a10.41,10.41,0,0,1-.506,3.139,5.719,5.719,0,0,1-5.443,4.092c-3.273.184-6.559.167-9.84.177-2.174.007-4.354,0-6.52-.161a6.176,6.176,0,0,1-5.922-5.789,15.766,15.766,0,0,1-.106-1.738c-.008-4.333-.035-8.666.019-13a10.011,10.011,0,0,1,.508-3.026,5.794,5.794,0,0,1,5.443-4.1c2.277-.174,4.571-.133,6.857-.183.45-.01.9,0,1.35,0Zm-.013,4.8a9.145,9.145,0,1,0,9.16,9.189A9.12,9.12,0,0,0,106.921,98.831Zm9.213,1.863a1.82,1.82,0,1,0-1.828-1.8A1.819,1.819,0,0,0,116.134,100.693Z" transform="translate(-78.798 -79.838)" fill="#3c0f34"/>
      <path id="Path_60" data-name="Path 60" d="M159.687,153.874a5.9,5.9,0,1,1-5.823-5.973A5.884,5.884,0,0,1,159.687,153.874Z" transform="translate(-125.652 -125.663)" fill="#3c0f34"/>
    </g>
  </g>
</svg>


    </motion.a>
  </>
);

export default NavBar;