import React from 'react';
import { motion } from 'framer-motion';
import { Instagram, ShoppingCart } from 'lucide-react';
import NavBar from './NavBar';

const Homepage = () => {
  return (
    <div>
      <img className="absolute h-screen overflow-hidden -z-10 w-screen" src={require('../assets/images/mask.webp')} alt="" />
    
      <div style={{fontFamily:'Avaaa'}} className="min-h-screen overflow-hidden max-w-[1500px] mx-auto">
        <NavBar />
        <div className="container mx-auto px-4 py-12 flex flex-col items-center justify-center min-h-screen">
     
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
            className="text-center border-none"
          >
            <motion.img 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, ease: "easeInOut" }}
              className="border-none w-[68%] md:w-[60%] mx-auto"
              src={require('../assets/images/lali_trans2.gif')} 
              alt="Lali Animation"
            />
             {/* <video 
            className="h-44  border-none  w-full mx-auto"
            autoPlay
            loop
            muted
            playsInline
            
          >
            <source className='border-none ' src={require("../assets/images/lalin.gif")} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
          
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.5 }}
              className="space-y-4"
            >
              <h1 className='text-[#5c1c54] md:text-[18px] mt-2 font-medium'>
                Our website will be <span className='font-bold'>live soon</span>, until then <br /> 
                join our Instagram community <a className='text-[#451443]' href="https://www.instagram.com/lali_india" target="_blank" rel="noopener noreferrer">@lali_india</a>
              </h1>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;